import React from "react";
import { Box, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import { useLocalization } from "gatsby-theme-i18n";
import styled from "@emotion/styled";
import _ from "lodash";
import moment from "moment";
import NewLayout from "../../components/NewLayout";
import Container from "../../components/Container";
import investorsHero from "../../cms/investorsHero/investorsHero";
import LatestUpdates from "../../components/LatestUpdates";
import ArrowButton from "../../components/ArrowButton";
import SharePriceData from "../../components/SharePriceData";
import Hero from "../../components/Hero";
import financialCalendars from "../../cms/financialCalendars/financialCalendars";
import financialInformationDocs from "../../cms/financialInformationDocs/financialInformationDocs";
import { transformArticleDate } from "../../utils/screen";
import DocumentList from "../../components/DocumentList";

const FINANCIAL_REPORTS_TYPES = [
  "Press_Release",
  "Financial_Reports_Presentations",
  "Excel",
  "Financial_Reports",
  "Recording",
];

function InvestorsLandingPage() {
  const format = "YYYY-MM-DD";
  const { locale, config } = useLocalization();
  const hrefLang = config.find((conf) => conf.code === locale)?.hrefLang;
  const currentDate = moment(moment(), format);
  const [events, setEvents] = React.useState([]);
  const financialDocs = financialInformationDocs[locale].filter(
    ({ attributes: { category, document_subcategory } }) =>
      FINANCIAL_REPORTS_TYPES.includes(category) &&
      document_subcategory.data !== null,
  );
  const lastYear = financialDocs.map(({ attributes }) => attributes.publish_year).sort().reverse()[0];

  const orderedCategories = _.uniqBy(
    _.orderBy(
      financialDocs.filter(
        ({ attributes }) => attributes.publish_year === lastYear,
      ),
      "attributes.document_subcategory.data.attributes.publish_date",
      "desc",
    ).map(({ attributes: { document_subcategory } }) => ({
      title: document_subcategory?.data?.attributes?.title,
      publish_date: document_subcategory?.data?.attributes?.publish_date,
      documents: financialDocs
        ?.filter(
          ({ attributes }) =>
            attributes?.document_subcategory?.data?.attributes?.title ===
            document_subcategory?.data?.attributes?.title,
        ),
    })),
    "title",
  );

  React.useEffect(() => {
    const currentEvents = financialCalendars[locale];
    if (!_.isEmpty(currentEvents)) {
      setEvents(currentEvents.filter(({ attributes }) => moment(attributes.publish_date, format).diff(currentDate) >= 0));
    }
  }, []);

  return (
    <NewLayout apps="investors">
      <Hero items={investorsHero[locale]} kind="Investors" />
      <Container pt={{ xs: 5, sm: 10 }} pb={{ xs: 5, sm: 8 }}>
        <Grid container spacing={4}>
          <StyledGrid item xs={12} sm={12} lg={4}>
            <StyledBox>
              <Typography variant="h4">
                Share Price
              </Typography>
              <SharePriceData />
            </StyledBox>
            <StyledArrowButton
              path="/investors/share"
              title="View all"
              isDark
            />
          </StyledGrid>
          <StyledGrid item xs={12} sm={6} lg={4}>
            <StyledBox>
              <Typography variant="h4">Latest Financial Information</Typography>
              <Stack spacing={2}>
                {orderedCategories.map(({ title, publish_date, documents }) => (
                  <Box>
                    <Typography
                      color="neutral.main"
                      variant="small"
                      display="block"
                    >
                      {transformArticleDate(publish_date, hrefLang)}
                    </Typography>
                    <Typography fontWeight={700}>{title}</Typography>
                    <DocumentList
                      items={documents}
                      direction="row"
                      mt="0 !important"
                    />
                  </Box>
                ))}
              </Stack>
            </StyledBox>
            <StyledArrowButton
              path="/investors/financial/financial-reports"
              title="View all"
              isDark
            />
          </StyledGrid>
          <StyledGrid item xs={12} sm={6} lg={4}>
            <StyledBox>
              <Typography variant="h4">
                Financial Calendar
              </Typography>
              {_.isEmpty(events) ? (
                <Box width="100%" display="flex" justifyContent="center">
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  {events.map(({ attributes: { event, publish_date } }) => (
                    <Stack>
                      <Typography variant="comment">{transformArticleDate(publish_date, hrefLang)}</Typography>
                      <Typography fontWeight={700}>{event}</Typography>
                      <Box my={1} sx={{ borderBottom: (theme) => `1px solid ${theme.palette.primary.main}` }} />
                    </Stack>
                  ))}
                </>
              )}
            </StyledBox>
            <StyledArrowButton
              path="/investors/financial/calendar"
              title="View all"
              isDark
            />
          </StyledGrid>
        </Grid>
      </Container>
      <LatestUpdates title="Latest updates" buttonTitle="View all" isDark />
      {/* <Box
        sx={{
          backgroundColor: theme => theme.palette.primary.main,
          paddingY: { xs: "65rem", sm: "28rem" },
        }}
      />
      <Box sx={{ marginTop: { xs: "-130rem", sm: "-60rem" } }}>
        <Container>
          <InterestedItems isDark items={interestedItems} />
        </Container>
      </Box>
      <Container py={10}>
        <Stack mb={5} spacing={1} direction="row" alignItems="baseline">
          <Typography variant="h3" sx={{ width: "60%" }}>
            <Trans>Useful Shareholder Information</Trans>
          </Typography>
          <Box
            sx={{
              width: "100%",
              borderBottom: theme => `1px solid ${theme.palette.primary.main}`,
            }}
          />
        </Stack>
        <Grid container spacing={2}>
          {usefulItems.map(({ label, path, description }) => (
            <Grid item xs={12} sm={6} md={3}>
              <ImageCard
                sx={{ height: "250px !important" }}
                title={label}
                linkPath={path}
                description={description}
                linkTitle={<Trans>Read more`}
                isSmall
              />
            </Grid>
          ))}
        </Grid>
      </Container> */}
    </NewLayout>
  );
}

const StyledGrid = styled(Grid)(({ theme }) => `
  position: relative;
  ${theme.breakpoints.up("lg")} {
    min-height: 700px;
  }
`);

const StyledArrowButton = styled(ArrowButton)(({ theme }) => `
  margin-top: ${theme.spacing(1)};
  ${theme.breakpoints.up("lg")} {
    position: absolute;
    bottom: 0;
  }
`);

const StyledBox = styled(Box)(({ theme }) => `
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  align-content: start;
  overflow-y: hidden;
  ${theme.breakpoints.down("lg")} {
    max-height: 600px;
  }
  ${theme.breakpoints.up("md")} {
    height: 580px;
  }
  div, a {
    margin-top: ${theme.spacing(1)};
    width: 100%;
  }
`);

export default InvestorsLandingPage;
